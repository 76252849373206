svg.custom .node text:nth-of-type(2)::after {
    content: ' ?'; /* Gebruik een vraagteken om te testen */
    color: #ffffff; /* Maak het wit */
    font-size: 14px;
    padding-left: 5px;
  }

.editForm .gender {
    display: none; /* Verberg het geslacht */
  }

svg.custom g use:nth-of-type(2) {
    cursor: pointer;
}
svg.custom g use:nth-of-type(1) {
    cursor: pointer;
}

svg.custom .node use {
    fill: #ffffff
}

.legend {
    display: flex;
    align-items: center;
    gap: 20px;
}