  :root {
    --rs-btn-subtle-hover-bg: #cdd2de;
    --rs-border-primary: #cdd2de;
  }
  
  .acoordion-container {
    padding: 50px;
    max-width: 70%;
    padding-top: 0;
  }

  .acoordion-container a {
    color: #11101D;
    text-decoration: underline;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    .acoordion-container {
        max-width: 90%;
    }

  }