/* styles.css

body {
    font-family: 'Times New Roman', Times, serif;
    margin: 0;
    padding: 0;
    background-color: #f4f4f4;
}

header {
    background-color: #fff;
    padding: 20px;
    text-align: center;
    border-bottom: 2px solid #000;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav ul li {
    display: inline;
    margin: 0 15px;
}

nav ul li a {
    text-decoration: none;
    color: #000;
}

main {
    padding: 20px;
}

article {
    margin-bottom: 20px;
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
}

footer {
    text-align: center;
    padding: 10px;
    background-color: #fff;
    border-top: 2px solid #000;
} */

body {
  background-color: #E4E9F7;
}


/* Google Fonts Import Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #11101d;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebar.close{
  width: 78px;
}
.sidebar .logo-details{
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebar .logo-details i{
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  cursor: pointer;
}
.sidebar .logo-details .logo_name{
  font-size: 22px;
  color: #fff;
  font-weight: 600;
  transition: 0.3s ease;
  transition-delay: 0.1s;
}
.sidebar.close .logo-details .logo_name{
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links{
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
}
.sidebar.close .nav-links{
  overflow: visible;
}
.sidebar .nav-links::-webkit-scrollbar{
  display: none;
}
.sidebar .nav-links li{
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebar .nav-links li:hover{
  background: #1d1b31;
}
.sidebar .nav-links li .iocn-link{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar.close .nav-links li .iocn-link{
  display: block
}
.sidebar .nav-links li i{
  height: 50px;
  min-width: 78px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.sidebar .nav-links li.showMenu i.arrow{
  transform: rotate(-180deg);
}
.sidebar.close .nav-links i.arrow{
  display: none;
}
.sidebar .nav-links li a{
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebar .nav-links li a .link_name{
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebar.close .nav-links li a .link_name{
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li .sub-menu{
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebar .nav-links li.showMenu .sub-menu{
  display: block;
}
.sidebar .nav-links li .sub-menu a{
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
}
.sidebar .nav-links li .sub-menu a:hover{
  opacity: 1;
}
.sidebar.close .nav-links li .sub-menu{
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebar.close .nav-links li:hover .sub-menu{
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebar .nav-links li .sub-menu .link_name{
  display: none;
}
.sidebar.close .nav-links li .sub-menu .link_name{
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebar .nav-links li .sub-menu.blank{
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebar .nav-links li:hover .sub-menu.blank{
  top: 50%;
  transform: translateY(-50%);
}

.sidebar .profile-details{
  position: fixed;
  bottom: 0;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details{
  background: none;
}
.sidebar.close .profile-details{
  width: 78px;
}
.sidebar .profile-details .profile-content{
  display: flex;
  align-items: center;
}
.sidebar .profile-details img{
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #1d1b31;
  transition: all 0.5s ease;
}
.sidebar.close .profile-details img{
  padding: 10px;
}
.sidebar .profile-details .profile_name,
.sidebar .profile-details .job{
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebar.close .profile-details i,
.sidebar.close .profile-details .profile_name,
.sidebar.close .profile-details .job{
  display: none;
}
.sidebar .profile-details .job{
  font-size: 12px;
}
.home-section{
  position: relative;
  background: #E4E9F7;
  min-height: 100vh;
  left: 78px;
  width: calc(100% - 78px);
  transition: all 0.5s ease;
}
.sidebar.close ~ .home-section{
  left: 78px;
  width: calc(100% - 78px);
}
.home-section .home-content{
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text{
  color: #11101d;
  font-size: 35px;
}
.home-section .home-content .bx-menu{
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text{
  font-size: 26px;
  font-weight: 600;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 99;
}

.overlay.visible {
  display: block;
}

/* Main Newspaper Layout */
.newspaper-container {
    width: 90%;
    margin: 20px auto;
    padding: 20px;
    background-color: white;
    padding-left: 40px;
    padding-right: 40px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    max-width: 1200px;
  }

  /* Remove content shift */
  .newspaper-container {
    transition: none;
  }

  .newspaper-header {
    text-align: center;
    border-bottom: 3px solid black;
    padding-bottom: 20px;
    margin-bottom: 20px;
    overflow-wrap: break-word;
  }

  .newspaper-header h1 {
    font-size: 3em;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
  }

  .newspaper-header h1 p {
    font-size: 20px;
    text-transform: none;
    margin: 0;
    font-family: unset;
  }

  .newspaper-date {
    font-size: 1.2em;
    margin-top: -10px;
    color: #666;
  }

  /* Multi-Column Layout */
  .article-section-3 {
    column-count: 3;
    column-gap: 40px;
  }

  .article-section-6 {
    column-count: 6;
    column-gap: 40px;
  }

  .article-section-2-1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
    column-gap: 40px;
  }

  .article-section-1-2 {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 40px;
  }

  .headline {
    display: flex;
    align-items: center;
    flex-direction: column;

  }

  .headline h4 {
    font-size: 3em;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    text-transform: uppercase;
    text-align: justify;
    margin: 0;
  }

  .headline img {
    width: 90%;
  }
  

  .article {
   
    /* margin-bottom: 30px; */
  }

  .article.no-break {
    break-inside: avoid-column;   
  }

  .article-section {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #ddd;
  }

  .first {
    border-top: none;
  }

  .article h2 {
    font-size: 1.8em;
    font-weight: bold;
    font-family: 'Times New Roman', Times, serif;
    margin-bottom: 10px;
  }

  p {
    font-size: 15px; /* of een andere vaste waarde */
  }

  .newspaper-container .article p {
    text-align: justify;
    font-size: 15px;
    line-height: 1.6em;
  }

  /* Image Styling */
  .article img {
    width: 100%;
    margin-bottom: 0px;
  }

  .first-title {
    margin-top: 0;
  }

  .author {
    font-family: 'Times New Roman', Times, serif;
    margin-top: 5px;
    line-height: 0.9;
  }

  .divider {
    border-top: 2px solid #333;
  }

  /* Footer Styling */
  .newspaper-footer {
    text-align: center;
    /* margin-top: 40px; */
    padding-top: 20px;
    border-top: 2px solid #333;
    font-size: 0.9em;
    color: #999;
  }

  /* Newspaper mobile */
  @media (max-width: 768px) {
    .article-section {
      column-count: 1;
    }

    .article-section-2-1 {
      grid-template-columns: 1fr;
    }

    .article-section-1-2 {
      grid-template-columns: 1fr;
    }

    .article-section-3 {
      column-count: 1;
    }

    .article-section-6 {
      column-count: 1;
    }

    .newspaper-header h1 {
        font-size: 2.5em;
      }

    .newspaper-date {
        font-size: 0.8em;
        margin-top: -5px;
    }

    .newspaper-container {
      padding-left: 15px;
      padding-right: 15px;
      width: 85%;
      margin-top: 60px;
    }

    .newspaper-container .article p {
      text-align: justify;
      font-size: 15px;
      line-height: 1.6em;
    }
    
  }

  .rotate-50 {
    transform: rotate(50deg);
  }

  /* pages */

  .page-container {
    position: relative;
    width: calc(100% - 78px);
    left: 78px;
    min-height: 100vh;
    background: #E4E9F7;
    min-height: 100vh;
  }

  .page-header {
    padding: 50px;
    max-width: 70%;
  }

  svg.custom {
    background-color: #E4E9F7;
    cursor: grab;
  }

  svg.custom .node.male>rect {
    fill: #9FBEE2;
  }

  svg.custom .node.female>rect {
    fill: #E5AFB2;
  }

  .bft-edit-form {
    position: fixed !important;
  }

  .female .bft-edit-form-header {
    background: #E5AFB2 !important;
  }

  .male .bft-edit-form-header {
    background: #9FBEE2 !important;
  }


  @media (max-width: 420px) {
    .sidebar.close .nav-links li .sub-menu{
      display: none;
    }
  }
  


  .bft-light {
    /* position: static !important; */
  }

  .not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    flex-direction: column;
  }

  .not-found h1 {
    font-size: 15em;
    margin: 0;
    color: #11101D;
    opacity: 0.6;
  }

  .not-found h2 {
    font-size: 2em;
    margin: 0;
    margin-bottom: 20px;
    color: #11101D;
    opacity: 0.6;
  }
  
  .not-found p {
    max-width: 448px;
    color: #11101D;
    /* opacity: 0.6; */
  }

  .download-btn-container {
    display: flex;
    justify-content: flex-end;
    width: 90%;
    max-width: 1200px;
  }

  .download-btn {
    background-color: #11101D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    gap: 10px;
    display: flex;
    align-items: center;
  }

  .download-btn:hover {
    background-color: #1D1B30;
  }

  .download-btn p {
    margin: 0;
  }

  .page-content {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .book-container {
    margin: 15px auto;
    width: 30%;
    /* background: white; */
    border-radius: 4px;
    overflow: hidden;
    /* padding: 50px; */
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
    cursor: pointer;
  }

  .books-container {
    column-count: 2;
    column-gap: 20px;
  }

  .link {
    color: #11101D;
    text-decoration: underline;
  }

    /* Mobile sidebar */
    @media (max-width: 768px) {
      .sidebar.close {
          width: 0px;
      }
  
      .home-section {
          left: 0;
          width: calc(100%);
      }
  
      .sidebar.close ~ .home-section {
          left: 0;
          width: calc(100%);
      }
  
      .sidebar .nav-links li i {
          /* min-width: 50px; */
      }
  
      .sidebar.close .logo-details i {
          color: #11101D;
          /* min-width: 50px; */
      }
  
      .page-container {
          width: calc(100%);
          left: 0;
      }

      .page-header {
        max-width: 90%;
      }

      .sidebar.close .nav-links{
        display: none;
      }

      .sidebar.close .logo-details{
        position: absolute;
      }

      .logo {
        display: none;
      }

      .download-btn-container {
        /* display: none; */
      }

      .books-container {
        column-count: 1;
        column-gap: 20px;
        display: flex;
        flex-direction: column;
      }

      .book-container {
        width: 60%;
      }
  }